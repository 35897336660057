import React, { useState, useEffect,useContext } from "react";
import ReactPlayer from 'react-player';
import {usePageVisibility} from "./../utils/pageVisibility";
import { PlayerControls } from "./PlayerControls";
import "./Player.scss";
import classNames from "classnames";
import {DEBUG} from "./../Constants";

import { PlayerContext } from "../context/player-context";
import { PlaylistContext } from "../context/playlist-context";

//hooks: https://github.com/CookPete/react-player/issues/844
export const Player = (props) => {
  const [playerState, playerDispatch] = useContext(PlayerContext);
  const [playlistState, playlistDispatch] = useContext(PlaylistContext);

  const playerComponent = React.createRef();
  const tabVisible = usePageVisibility();//autoplay policy will block the player if the tab is not visible.

  const [mediaUrl, setMediaUrl] = useState();
  const [tracksHistory,setTracksHistory]= useState([]);
  const [skipTimer, setSkipTimer] = useState();

  //set loader when an URL is set or that the 'playing' state is true.
  useEffect(() => {
    playerDispatch({
      type:     "SET_MEDIA_STATUS",
      payload:  (playerState.playing && mediaUrl) ? 'loading' : ''
    });

  }, [playerState.playing,mediaUrl]);

  //load URL from source into player if the track num & source num have been defined.
  useEffect(() => {

    if (!playerState.activePair) return;

    //stop all
    setMediaUrl('');
    playerDispatch({
      type: "STOP_CURRENT_PAIR"
    });


    //init new ones
    var trackNum = playerState.activePair?.[0];
    if (!trackNum) return;

    var track = playlistState.track[trackNum - 1];

    var sourceNum = playerState.activePair?.[1];
    if (!sourceNum) return;

    var url = track.location[sourceNum - 1];
    if (!url) return;

    console.log("PLAYER RECEIVED REQUESTED URL",url);

    setMediaUrl(url);

  }, [playerState.activePair]);

  const handleOnError = state => {
    alert("ERROR");

    playerDispatch({
      type: "SET_MEDIA_STATUS",
      payload:'error'
    });

  }

  const handleOnEnded = state => {

    playerDispatch({
      type: "SET_MEDIA_STATUS",
      payload:'ended'
    });

  }

  const handleSeekTo = (float) => {
    playerComponent.current.seekTo(parseFloat(float))
  }

  //player events
  const handleOnReady = state => {
    console.log("MEDIA READY");

    //Media as been successfully loaded - we can now set the active pair as the current one.
    playerDispatch({
      type: "SET_CURRENT_PAIR",
      payload:playerState.activePair
    });

    if(playerState.playing){
      //TOUFIX URGENT
      /*
      //we want to skip song if it has not started after an arbitrary 10 seconds,
      //since players don't always fire errors (eg. geoblocked Youtube videos)
      const timer = setTimeout(() => {
        console.log("This media does not seems to start, force error.");
        playerDispatch({
          type: "SET_MEDIA_STATUS",
          payload:'error'
        });
      }, 5000);
      */
    }
  }

  const handleOnStart = state => {
  }

  const handleOnDuration = duration => {
    //set source duration
    playerDispatch({
      type: "SET_MEDIA_DURATION",
      payload:duration * 1000
    });
  }


  const handleOnPlay = state => {
    playerDispatch({
      type: "SET_MEDIA_STATUS",
      payload:'playing'
    });
  }

  const handleOnPause = state => {
    playerDispatch({
      type: "SET_MEDIA_STATUS",
      payload:'paused'
    });
  }

  const handleOnProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!playerComponent || !playerComponent.current || !playerComponent.current.state) return; //bugfix
    if (!playerComponent.current.state.seeking) {
      playerDispatch({
        type: "SET_MEDIA_PROGRESS",
        payload:state.played
      });
    }
  }

  return (
    <div className="player">
      <div
      className={classNames({
        'embed-player':  true,
        'active':       playerState.widget
      })}
      >
        <ReactPlayer
        ref={playerComponent}
        playing={playerState.playing}
        url={mediaUrl}
        volume={playerState.volume}
        muted={playerState.muted}
        controls
        onReady={handleOnReady}
        //onStart={handleOnStart}
        onPlay={handleOnPlay}
        onProgress={handleOnProgress}
        onDuration={handleOnDuration}
        onPause={handleOnPause}
        onEnded={handleOnEnded}
        onError={handleOnError}
      />
      </div>
      <PlayerControls
      hasNext={props.hasNext}
      hasPrevious={props.hasPrevious}
      onNext={props.onNext}
      onPrevious={props.onPrevious}
      onSeekTo={handleSeekTo}
      />
    </div>
  )
}

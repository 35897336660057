import Api from "./../Api";
import {ImportRequest} from "./../models/ImportRequest";
import ReactPlayer from 'react-player'; //TOUFIX could import just the canPlay fn ?

export const getUrlDomain = (url) => {
  if(!url) return;

  var domain;
  //find & remove protocol (http, ftp, etc.) and get domain

  if (url.indexOf("://") > -1) {
    domain = url.split('/')[2];
  }
  else {
    domain = url.split('/')[0];
  }

  //find & remove www
  if (domain.indexOf("www.") > -1) {
    domain = domain.split('www.')[1];
  }

  domain = domain.split(':')[0]; //find & remove port number
  domain = domain.split('?')[0]; //find & remove url params

  return domain;
}

//https://stackoverflow.com/a/10903003/782013
export const shortUrl = (url, l) => {
  if(!url) return;
  l = typeof(l) != "undefined" ? l : 50;
  var chunk_l = (l/2);
  url = url.replace("http://","").replace("https://","");

  if(url.length <= l){ return url; }

  var start_chunk = shortString(url, chunk_l, false);
  var end_chunk = shortString(url, chunk_l, true);
  return start_chunk + "..." + end_chunk;
}

function shortString(s, l, reverse){
    var stop_chars = [' ','/', '&'];
    var acceptable_shortness = l * 0.80; // When to start looking for stop characters
    reverse = typeof(reverse) != "undefined" ? reverse : false;
    var s = reverse ? s.split("").reverse().join("") : s;
    var short_s = "";

    for(var i=0; i < l-1; i++){
        short_s += s[i];
        if(i >= acceptable_shortness && stop_chars.indexOf(s[i]) >= 0){
            break;
        }
    }
    if(reverse){ return short_s.split("").reverse().join(""); }
    return short_s;
}

export const getRequestImporter = async(request) => {

  if (!request.url){
    throw new Error("Import request is not valid.");
  }

  return Api.request({
    method: 'get',
    url: '/v2/importer/',
    params: {
      url:      request.url,
      options:  request.options
    },
  })
  .then(resp => resp.data.importer)
  .then(function(importer){
    if (!importer){
      throw new Error('No importer found for this URL.');
    }else{
      return importer;
    }
  })
}

export const getWizardRequestPath = (request) => {
  if (!request) return;
  if (!request?.url) return false;
  return '/wizard/' + encodeRequest(request);
}

export const getTracklistRequestPath = (jspf) => {
  const import_url = getJspfMetaByKey(jspf,'import_url');
  const import_options = getJspfMetaByKey(jspf,'import_options');
  const post_id = getJspfMetaByKey(jspf,'post_id');

  if (!post_id) return;

  if (import_url){
    return '/radio/' + post_id;
  }else{
    return '/playlist/' + post_id;
  }
}



export const encodeRequest = (request) => {

  const encodeUnicode = (str) => {
    //avoid DOMexception error
    //https://attacomsian.com/blog/javascript-base64-encode-decode
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
    }));
  }
  return encodeUnicode(JSON.stringify(request));
}

export const decodeRequest = (string) => {
  //avoid DOMexception error
  //https://attacomsian.com/blog/javascript-base64-encode-decode
  const decodeUnicode = (str) => {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    //TOUFIX handle errors if string is malformed
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  try{
    const obj = JSON.parse(decodeUnicode(string));
    return new ImportRequest(obj.url,obj.options);
  }catch (error) {
    console.log("error decoding request");
  }

}

export const getPageSlug = (location) => {
  let pageSlug = location.pathname;
  pageSlug.toLowerCase();
  pageSlug = pageSlug.split("/")[1];
  return pageSlug;
}

//get JSPF meta by attribute name
export const getJspfMetaByKey = (jspf,key) => {
  const metas = jspf?.meta
  if (!metas) return;

  let value;

  metas.forEach(function(meta){
    const metaKey = Object.keys(meta)[0];
    if (metaKey === key){
      value = meta[metaKey];
    }
  });

  return value;
}

//return playable urls
export const filterPlayableUrls = (urls) =>{
  var output = [];
  var urls = [...new Set(urls)];//remove duplicates

  urls.forEach(function(url){
    if ( ReactPlayer.canPlay(url) ){
      output.push(url);
    }
  })

  return output;

}

//return non-playable urls
export const filterNotPlayableUrls = (urls) =>{
  const playableUrls = filterPlayableUrls(urls);
  return urls.filter(x => !playableUrls.includes(x));
}

export const hasNextTrack = (player,playlist,trackNum,reverse) => {
  if (!trackNum) return;

  const trackCount = playlist.track.length;
  if ( trackCount<2 ) return;

  if (reverse === undefined) reverse = player.reverse;

  let newTrackNum = reverse ? trackNum-1 : trackNum+1;

  //handle limits when looping is enabled
  if (player.looping){
    if ((trackNum === trackCount) && !reverse){ //last track & looping forward
      newTrackNum = 1;
    }else if ((trackNum === 1) && reverse){ //first track & looping backwards
      newTrackNum = trackCount;
    }
  }

  let newTrack = playlist.track[newTrackNum-1];

  //console.log("HAS NEXT TRACK FOR #"+trackNum+"? IS REVERSE:"+reverse+" FOUND #"+newTrackNum);

  if (!newTrack) return;
  return newTrackNum;
}

export const skipTrack = (player,dispatch,playlist,reverse) => {

  const skippedTrackNum = player.activePair[0];

  if (reverse === undefined) reverse = player.reverse;

  const newTrackNum = hasNextTrack(player,playlist,skippedTrackNum,reverse);

  console.log("SKIP TRACK#"+skippedTrackNum+" - REVERSE? "+reverse+" FOUND:"+newTrackNum);
  console.log(player);

  if ( newTrackNum ){

    const firstSkippedTrackNum = player.skipTrackHistory[0];

    //avoid a skip loop
    if (firstSkippedTrackNum === newTrackNum) {
      console.log("SKIP LOOP!!! STOP PLAYLIST!!!" + newTrackNum);
      dispatch({
        type: "TOGGLE_PLAYING",
        payload: false
      });
    }else{

      //store the tracks skipped so we'll be able to avoid a skip loop (only if we are currently playing).
      if(player.playing) {

        dispatch({
          type: "SET_REVERSE",
          payload: reverse
        });

        dispatch({
          type: "INCREMENT_SKIP_TRACK_HISTORY",
          payload: skippedTrackNum
        });

      }

      console.log("SWITCH TO TRACK#",newTrackNum);

      dispatch({
        type: player.playing ? "SET_REQUESTED_PAIR" : "SET_ACTIVE_PAIR",
        payload: [newTrackNum]
      });
    }

  }else{
    if (reverse){
      console.log("TRACKLIST START REACHED");
    }else{
      console.log("TRACKLIST END REACHED");
    }
    dispatch({
      type: "TOGGLE_PLAYING",
      payload: false
    });
  }
}

import React, { useEffect,useState,useContext } from "react";
import {DEBUG} from "./../Constants";
import classNames from "classnames";
import "./Playlist.scss";
import { Player } from "./Player";
import { Tracklist } from "./Tracklist";
import { PlayerContext } from "../context/player-context";
import { PlaylistContext } from "../context/playlist-context";
import {hasNextTrack,skipTrack} from "./../utils/Utils";

export const Playlist = (props) => {
  const [playerState, playerDispatch] = useContext(PlayerContext);
  const [playlistState, playlistDispatch] = useContext(PlaylistContext);
  const [trackNum,setTrackNum] = useState(props.trackNum ? props.trackNum : 1); //requested track number
  const [hasNext,setHasNext] = useState();
  const [hasPrevious,setHasPrevious] = useState();
  const [favorited,setFavorited] = useState(false);
  const [playedHistory, setPlayedHistory] = useState([]);

  //update JSPF if prop changes
  useEffect(() => {
    playlistDispatch({
      type: "LOAD_JSPF",
      payload: props.jspf
    });
  }, [props.jspf]);

  //when queue is set, append the tracks array to the current playlist.
  //TOUFIX URGENT required here ? Would be better in the radio component.
  useEffect(() => {
    if(!props.trackQueue) return;

    playlistDispatch({
      type: "QUEUE_TRACKS",
      payload: props.trackQueue
    });

  }, [props.trackQueue]);

  //trackNum prop is updated
  useEffect(() => {
    playerDispatch({
      type: playerState.playing ? "SET_REQUESTED_PAIR" : "SET_ACTIVE_PAIR",
      payload: [trackNum]
    });
  }, [trackNum]);

  //for player controls
  useEffect(() => {


    const trackNum = playerState.activePair[0];
    const canNext = hasNextTrack(playerState,playlistState,trackNum,false);
    const canPrevious = hasNextTrack(playerState,playlistState,trackNum,true);

    setHasNext(canNext);
    setHasPrevious(canPrevious);

  }, [playlistState.track,playerState.activePair,playerState.looping]);

  //keep an history of the played tracks.
  useEffect(() => {
    if (playerState.media_status !== 'playing') return;
    setPlayedHistory([
      ...playedHistory,
      playerState.currentPair[0]
    ])
  }, [playerState.media_status]);

  useEffect(() => {
    console.log("STATUS UPDATED:"+playerState.media_status);
  }, [playerState.media_status]);

  const handleExport = () => {
    console.log("EXPORT JSPF");
    console.log(playlistState);
    /*
    const img = map.getCanvas().toDataURL('image/png',1.0);
    const link = document.createElement("a");
    link.href = img;
    link.setAttribute("download", "mapSnapshot.png"); //or any other extension
    document.body.appendChild(link);
    link.click();
    */
  }


  const handleReload = () => {
    if (typeof props.onReload === 'function') {
      props.onReload();
    }
  }

  const handleNext = () => {
    skipTrack(playerState,playerDispatch,playlistState,false);
  }
  const handlePrevious = () => {
    skipTrack(playerState,playerDispatch,playlistState,true);
  }

  return (
    <div
    className={classNames({
      playlist: true
    })}
    itemScope
    itemType="http://schema.org/MusicPlaylist"
    >
      <Player
      hasNext={hasNext}
      hasPrevious={hasPrevious}
      onNext={handleNext}
      onPrevious={handlePrevious}
      />
      <Tracklist
      onSkip={handleNext}
      />
    </div>
  )

}

import React from "react";
import PlaylistPage from "./pages/playlist";
import { Container } from 'semantic-ui-react';
import "./App.scss";

const AppContent = (props) => {

  return (
    <Container className='page-content'>
      <PlaylistPage/>
    </Container>
  );
}

export default AppContent;

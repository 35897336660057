import React, { useState, useEffect,useContext } from "react";
import {DEBUG} from "./../Constants";
import { useHistory } from "react-router-dom";
import {ImportRequest} from "./../models/ImportRequest"
import {getWizardRequestPath,filterPlayableUrls,filterNotPlayableUrls,skipTrack} from "./../utils/Utils";
import classNames from "classnames";
import { PlayButton } from "./PlayButton";
import { TrackSourcesButton } from "./TrackSourcesButton";
import { TrackSource } from "./TrackSource";
import { Icon,Label,Dropdown } from 'semantic-ui-react';
import Api from "./../Api";
import "./Track.scss";

import { PlayerContext } from "../context/player-context";
import { PlaylistContext } from "../context/playlist-context";

export const Track = (props) => {

  const [playerState, playerDispatch] = useContext(PlayerContext);
  const [playlistState, playlistDispatch] = useContext(PlaylistContext);
  const track = playlistState.track[props.trackNum - 1];

  const [isApiRequest,setIsApiRequest] = useState(false);
  const [didApiTrackRequest,setDidApiTrackRequest] = useState(false);
  const [didApiLinkRequest,setDidApiLinkRequest] = useState(false);


  const checkPlayable = () => {
    const sourcesCount = track.location.length;
    return ( ( sourcesCount > 0) || !didApiLinkRequest );
  }

  const [playable,setPlayable] = useState(true);


  const spotifyID = getSpotifyTrackID(track);
  const trackImage = track.image ? 'url('+track.image+')' : ''
  const trackDurationText = track.duration ? track.duration : null;

  const [activeSourceNum, setActiveSourceNum] = useState(1);
  const [favorited, setFavorited] = useState(false);
  const [showSources, setShowSources] = useState(false);

  const [skipSourceHistory, setSkipSourceHistory] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!activeSourceNum) return;
    console.log("UPDATED TRACK#"+props.trackNum+" SOURCE NUM :",activeSourceNum);
  }, [activeSourceNum]);

  //populate links for an active or requested track.
  useEffect(() => {
    if (!props.active) return;

    if (playable){

      //do we need to get sources from the API ?

      const queryApi = (!track.location.length && !didApiLinkRequest);

      if ( queryApi ){
        DEBUG && console.log('FETCH TRACK LINKS FOR TRACK #'+props.trackNum);
        populateApiLinks()
        .catch(err => {
          console.log("FAILED FETCH TRACK LINKS FOR TRACK #"+props.trackNum);
          console.log(err);
          setPlayable(false);
        })
      }

    }

  }, [props.active]);

  //update "playable" capability when sources are updated
  useEffect(() => {

    const bool = checkPlayable();
    setPlayable(bool);

    DEBUG && console.log("SOURCES UPDATED FOR TRACK#"+props.trackNum+". IS TRACK PLAYABLE ?",bool);

  }, [track.location]);

  //load track in player.
  useEffect(() => {
    if (!props.active) return;
    if (!playable) return;

    playerDispatch({
      type: "SET_ACTIVE_PAIR",
      payload: [props.trackNum,activeSourceNum]
    });

  }, [props.active,track.location,activeSourceNum]);

  //skip unplayable track.
  useEffect(() => {
    if (!playerState.playing || !props.active || !playerState.playing || playable) return;
    skipTrack(playerState,playerDispatch,playlistState);
  }, [playerState.playing,props.active,playable]);

  //skip when track has ended
  useEffect(() => {
    if (props.status !== 'ended') return;
    skipTrack(playerState,playerDispatch,playlistState);
  }, [props.status]);

  //reset the skip status & skip history if a track IS playing (we're not skipping anymore)
  useEffect(() => {
    if (!skipSourceHistory.length) return;
    if (props.status !== 'playing') return;
    DEBUG && console.log("SKIP SOURCE ENDED, RESET SKIP HISTORY!");
    setSkipSourceHistory([]);

  }, [props.status]);

  const onTrackToggle = (e) => {

    if (props.current){
      console.log("...JUST TOGGLING TRACK...");
      playerDispatch({
        type: "TOGGLE_PLAYING"
      });
    }else if(playable){
      console.log("...STARTING TRACK...");

      playerDispatch({
        type: "SET_REQUESTED_PAIR",
        payload: [props.trackNum,activeSourceNum]//URGENT CHECK SOURCENUM
      });
    }

  }

  const handleSourceListToggle = () => {
    setShowSources(!showSources);
  }

  const handleGetSourceList = () => {
    populateApiLinks()
    .then(function(links){
      setShowSources(true);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  const handleFavoriteTrack = () => {
    console.log("BT FAVORITE TRACK");
  }
  const handleUnfavoriteTrack = () => {
    console.log("BT UNFAVORITE TRACK");
  }

  const handleSpotifyLink = () => {
    window.open('http://open.spotify.com/track/' + spotifyID, "_blank");
  }

  const redirectToWizardArtist = () => {
    const artist = track.creator;
    if (!artist) return;
    const request = new ImportRequest('artist:'+artist);
    const path = getWizardRequestPath(request);
    history.push(path);
  }

  const redirectToWizardAlbum = () => {
    const artist = track.creator;
    const album = track.album;
    if (!artist || !album) return;
    const request = new ImportRequest('artist:'+artist+':album:'+album);
    const path = getWizardRequestPath(request);
    history.push(path);
  }


  const populateApiLinks = async() => {
    if (didApiLinkRequest){
      throw('API links have already been requested for track' + trackToString(track));
    }

    if (didApiTrackRequest){
      throw("This track has already been searched" + trackToString(track));
    }else{

      setIsApiRequest(true);

      return getTrackForLinksRequest(track)
      .then(function(jspf){

        setDidApiTrackRequest(true);

        return fetchApiLinks(jspf)
        .then(function(links){

          setDidApiLinkRequest(true);

          jspf = mergeTrackLinks(jspf,links);

          playlistDispatch({
            type: "UPDATE_TRACK",
            payload: jspf
          });

          return true;


        })

      })
      .catch((error) => {
        setDidApiLinkRequest(true);
        throw(error);
      })

      .finally(function(){
        setIsApiRequest(false);
      })

    }

  }

  const hasNextSource = (sourceNum) => {
    if (!sourceNum) return;

    const sourceCount = track.location.length;
    if ( sourceCount<2 ) return;

    let newSourceNum = sourceNum+1;

    if (newSourceNum > sourceCount){ // loop from start
      newSourceNum = 1;
    }

    let newSource = track.location[newSourceNum-1];
    if (!newSource) return;

    return newSourceNum;
  }

  const skipSource = () => {

    const newSourceNum = hasNextSource(activeSourceNum);

    if ( newSourceNum ){

      const firstSkippedSourceNum = skipSourceHistory[0];

      //avoid a skip loop
      if (firstSkippedSourceNum === newSourceNum) {
        console.log("SOURCES LOOP FOR TRACK#"+props.trackNum+"!!! SWITCH TO NEXT TRACK!!!");
        setPlayable(false);
      }else{

        console.log("SKIP SOURCE #"+activeSourceNum+" FOR TRACK#",props.trackNum);

        //store the sources skipped so we'll be able to avoid a skip loop (only if we are currently playing).
        if(playerState.playing) {
          setSkipSourceHistory([
            ...skipSourceHistory,
            activeSourceNum
          ])
        }

        console.log("SWITCH TO SOURCE#",newSourceNum);
        setActiveSourceNum( newSourceNum );

      }

    }else{
      console.log("SOURCES END REACHED");
      setPlayable(false);
    }
  }

  const handleSourceSkip = () => {
    skipSource();
  }

  return (
    <div
    className={classNames({
      track:          true,
      playable:       playable,
      active:         props.active,
      current:        props.current,
      loading:        (props.status === 'loading'),
      playing:        (props.status === 'playing'),
      'api-loading':  isApiRequest,
      'sources-list': showSources
    })}
    itemProp="track"
    itemScope
    itemType="http://schema.org/MusicRecording"
    data-playable-links={track.location ? track.location.length : 0 }
    >
      <div className="track-row track-data">
        {track.trackNum && <meta itemProp="position" content={track.trackNum} />}
        {track.album && <meta itemProp="inAlbum" content={track.album} />}
        {track.image && <meta itemProp="image" content={track.image} />}
        {track.duration && <meta itemProp="duration" content={track.duration} />}
        <span className="track-before" onClick={onTrackToggle}>
          <PlayButton
          size='3em'
          disabled={!playable}
          error={!playable}
          status={props.status}
          progress={props.progress}
          />
          <div className="track-image" style={{backgroundImage: trackImage}}></div>
        </span>
        <span className="track-main">
          <p className="nowrap" itemProp="name" title={track.title}>{track.title}</p>
          {track.creator &&
            <p className="nowrap" itemProp="byArtist" title={track.creator}>{track.creator}</p>
          }
          {track.annotation &&
            <p className="nowrap track-annotation">{track.annotation}</p>
          }
          {spotifyID &&
            <Label className="track-duration" horizontal>{spotifyID}</Label>
          }
        </span>
        <span className="track-after">

          {trackDurationText &&
            <Label className="track-duration" horizontal>
              <Icon name='clock outline'/>
              {trackDurationText}
            </Label>
          }

        </span>
        </div>
    </div>
  );

}

const fetchApiTrack = async(track,service) => {

  if (!track.creator || !track.title){
    throw new Error("A track artist + title is required.");
  }

 const config = {
  method: 'post',
  url: '/v2/track/search/',
  params: {
    track: { //don't send the whole thing, just the useful data
      creator:track.creator,
      title:track.title,
      album:track.album
    },
    service:service
  }
 }

  console.log("search track requested! - " + trackToString(track));
  DEBUG && console.log(config);

  return Api.request(config)
  .then(resp => resp.data.track)
}

//our API needs a spotify ID to fetch links; so search for it first.
const getTrackForLinksRequest = async(track) => {

  if (!track.creator || !track.title){
    throw new Error("A track artist + title is required.");
  }

  const spotifyID = getSpotifyTrackID(track);

  if (spotifyID){
    return track;
  }else{

      console.log("No Spotify ID, try to fetch track", trackToString(track));

      return fetchApiTrack(track,'spotify')
      .then(function(apiJspf){

        if (!apiJspf){
          throw new Error("No matching track was found.");
        }

        //keep some of the old track values
        //TOUFIX TO IMPROVE !
        return {
          ...apiJspf,
          trackNum:track.trackNum,
          annotation:track.annotation,
          link:track.link,
          location:track.location
        }
      })

  }
}

const fetchApiLinks = async(track) => {

  const spotifyID = getSpotifyTrackID(track);

  if (!spotifyID){
    throw new Error("A Spotify ID is required");
  }else{

    const config = {
     method: 'post',
     url: '/v2/track/links/',
     params: {
       track: { //don't send the whole thing, just the useful data
         creator:track.creator,
         title:track.title,
         album:track.album,
         identifier:track.identifier
       }
     }
    }

    return Api.request(config)
    .then(resp => resp.data.links)
  }

}

const mergeTrackLinks = (track,links) => {

    if (links === undefined) return;

    let urls = [];
    links.map(link => {
        urls.push(link.url);
    });

    //new ones
    const newLocationUrls = filterPlayableUrls(urls);
    const newLinkUrls = filterNotPlayableUrls(urls);
    //concat
    let locationUrls = track.location.concat(newLocationUrls);
    let linkUrls = track.link.concat(newLinkUrls);
    //unique
    locationUrls = [...new Set(locationUrls)];
    linkUrls = [...new Set(linkUrls)];

    return {
      ...track,
      location:locationUrls,
      link:linkUrls
    }

}

const getSpotifyTrackID = (track) => {
  const identifiers = track?.identifier;
  if (!identifiers || !identifiers.length) return;

  var id = undefined;

  identifiers.every(url => {
    const regex = new RegExp("spotify\.com\/track\/(.*)");
    const match = url.match(regex);

    if (match[1]){
      id = match[1];
      return false;//break
    }
  })

  return id;
}

const trackToString = (jspf) => {
  return jspf.trackNum +' - '+jspf.creator + ' - ' + jspf.title;
}

import React, { useReducer, createContext } from "react";
import {DEBUG} from "./../Constants";

export const PlayerContext = createContext();

const initialState = {
  //selection
  activePair:[], //selected track+source
  currentPair:[], //current track+source (URL is loaded in player)
  //player
  media_status:'',
  media_duration:undefined,
  media_progress:undefined,
  skipTrackHistory:[], //to avoid infinite loops; we do store the skipped tracks until one is successfully played.
  reverse:false,
  //controls
  playing:false,
  volume:0.8,
  muted:false,
  looping: true,
  widget: false
}

const reducer = (state, action) => {
  console.log("player reducer, action:"+action.type,action.payload);

  switch (action.type) {

    //highligh a track+source
    case "SET_ACTIVE_PAIR":
      return {
          ...state,
        activePair:action.payload,
        currentPair:[],
        media_status:'loading'
      }
    break;

    //highligh a track+source & play it
    case "SET_REQUESTED_PAIR":
      return {
          ...state,
        activePair:action.payload,
        currentPair:[],
        media_status:'loading',
        playing:true
      }
    break;

    //once the media is loaded
    case "SET_CURRENT_PAIR":
      return {
          ...state,
        activePair:action.payload,
        currentPair:action.payload,
        media_status: state.playing ? 'loading' : ''
      }
    break;

    case "STOP_CURRENT_PAIR":

      return {
          ...state,
        currentPair:[],
        media_status:'',
        media_progress:undefined,
        media_duration:undefined

      }
    break;

    case "SET_REVERSE":

      var reverse = action.payload;
      if (reverse === undefined) reverse = state.reverse;
      var isSwitch = (reverse !== state.reverse);

      return {
        ...state,
        reverse:reverse,
        //if we go back and forth; we need to clean the skip history:
        //eg. track#5 is playing -> we rewind to track#4; which does not resolves -> it will try to play track#5 again -> history will be : [5,4,5] and it will consider it as a loop since first item of the array is 5.
        skipTrackHistory: isSwitch ? [] : state.skipTrackHistory
      }

    break;

    case "INCREMENT_SKIP_TRACK_HISTORY":

      return {
          ...state,
          skipTrackHistory:[...state.skipTrackHistory,action.payload],
      }

    break;

    case "SET_MEDIA_STATUS":

    var isPlaying = (action.payload === 'playing');

      var newState = {
          ...state,
        media_status:action.payload
      }


      if (isPlaying){
        //reset the skip stuff if a track IS playing (we're not skipping anymore)
        DEBUG && console.log("Reset skip history");
        newState = {
          ...newState,
          skipTrackHistory: [],
          reverse: false
        }
      }

      return newState;

    break;

    case "SET_MEDIA_DURATION":

      return {
          ...state,
        media_duration:action.payload
      }
    break;

    case "SET_MEDIA_PROGRESS":

      return {
          ...state,
        media_progress:action.payload
      }
    break;

    case "TOGGLE_PLAYING":

      var bool = (action.payload !== undefined) ? action.payload : !state.playing;

      return {
          ...state,
        playing:bool
      }
    break;

    case "TOGGLE_MUTED":

      var bool = (action.payload !== undefined) ? action.payload : !state.muted;

      return {
          ...state,
        muted:bool
      }
    break;

    case "TOGGLE_WIDGET":

      var bool = (action.payload !== undefined) ? action.payload : !state.widget;
      localStorage.setItem('show_embed',bool);

      return {
          ...state,
        widget:bool
      }
    break;


    case "TOGGLE_LOOPING":

      var bool = (action.payload !== undefined) ? action.payload : !state.looping;
      localStorage.setItem('looping',bool);

      return {
          ...state,
        looping:bool
      }
    break;

    case "SET_VOLUME":

      return {
          ...state,
        volume:parseFloat(action.payload)
      }
    break;

    default:
      throw new Error();
  }
};

export const PlayerContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PlayerContext.Provider value={[state, dispatch]}>
      {props.children}
    </PlayerContext.Provider>
  );
};

import React, { useReducer, createContext } from "react";
import jspfPlaylist from  "./../models/jspfPlaylist";
import jspfTrack from  "./../models/jspfTrack";

export const PlaylistContext = createContext();

const initialState = new jspfPlaylist();

const reducer = (state, action) => {
  console.log("playlist reducer, action:"+action.type,action.payload);

  switch (action.type) {

    case "LOAD_JSPF":
      var playlist = new jspfPlaylist(action.payload);

      return {
        ...state,
        ...playlist
      }
    break;

    case "QUEUE_TRACKS":

      var rawTracks = action.payload;
      var totalTracks = state.track.length;
      var newQueue = [];

      //build tracks
      rawTracks.forEach(function(jspf, index) {
        jspf.trackNum = totalTracks + index + 1;
        newQueue.push(new jspfTrack(jspf));
      });

      return{
        ...state,
        track:state.track.concat(newQueue)
      }

    break;

    case "UPDATE_TRACK":
      var jspf = new jspfTrack(action.payload);
      var index = jspf.trackNum - 1;

      //update only our track.
      //https://stackoverflow.com/questions/35628774/how-to-update-single-value-inside-specific-array-item-in-redux
      var newQueue = state.track.map(
        (track, i) => i === index ? action.payload : track
      )

      return {
          ...state,
        track:newQueue
      }

    break;

    default:
      throw new Error();
  }
};

export const PlaylistContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PlaylistContext.Provider value={[state, dispatch]}>
      {props.children}
    </PlaylistContext.Provider>
  );
};

//https://blog.liplex.de/axios-interceptor-to-refresh-jwt-token-after-expiration/
import {API_KEY} from "./Constants";
import Api from "./Api";

export class ApiTokenStorage {

  static LOCAL_STORAGE_TOKEN = 'spiff_token';

  static hasToken(): boolean {
    return this.getToken() !== null;
  }

  static getNewToken(): Promise<string> {

    console.log("Request new API token");

    return new Promise((resolve, reject) => {

      const config = {
        method: 'post',
        url: '/v2/auth/token/',
        data: {
          api_key:  API_KEY
        }
      }

      Api.request(config)
      .then(resp => resp.data.token)
      .then(function(token){
        ApiTokenStorage.storeToken(token);
        resolve(token);
      })
      .catch((error) => {
        reject(error);
      });
    })

  }

  static storeToken(token: string): void {
    console.log("Store API token");
    localStorage.setItem(this.LOCAL_STORAGE_TOKEN, token);
  }

  static clear(): void {
    console.log("Clear API token");
    localStorage.removeItem(this.LOCAL_STORAGE_TOKEN);
  }

  static getToken(): string | null {
    return localStorage.getItem(this.LOCAL_STORAGE_TOKEN);
  }
}

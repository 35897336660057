/*index.jsx*/
import React, { useState,useEffect } from "react"
import { Playlist } from "./../components/Playlist";
import { LastLocationPath } from "./../utils/LastLocationPath";
import {BACKEND_API_URL} from "./../Constants";
import {ImportRequest} from "./../models/ImportRequest";
import {useLocation} from "react-router-dom";
import { Loader } from 'semantic-ui-react';
import axios from "axios";
import {getJspfMetaByKey} from "./../utils/Utils";
import { PlayerContextProvider } from "./../context/player-context";
import { PlaylistContextProvider } from "./../context/playlist-context";

const PlaylistPage = (props) => {

  const [loading,setLoading] = useState(false);

  const [jspf,setJspf] = useState();

  const handleLoadSuccess = (request) => {
    console.log("PLAYLIST IMPORTED SUCCESSFULLY!",request);
  }

  //get the JSON data for this playlist
  const fetchPlaylistData = async() => {

    if (loading) return;

    setLoading(true);

    console.log("GET PLAYLIST DATAS");

    const config = {
     method: 'get',
     url: 'olive.jspf',
    }

    axios(config)
    .then(resp => resp.data)
    .then(function(item){
      return item.playlist;
    })
    .then(function(jspf){
      console.log("JSPF LOADED",jspf);
      setJspf(jspf);

    })
    .catch(err => {
      console.error(err); // log since might be a render err
    })
    .finally(function(){
      setLoading(false);
    })
  }

  //init
  useEffect(() => {
    fetchPlaylistData();
  }, []);

  //TOUFIX this should be a playlist item, not a radio item.
  return (
    <>
    {
      loading ?
      <Loader active inline='centered' />
      :
      <>
      <div id="header">
        <img src={process.env.PUBLIC_URL + '/header.jpg'}/>
        <div id="intro">
          <p>Avant le printemps, blottie bien au chaud, Olive aura entendu pas mal de ces morceaux.<br/>
          On espère qu'elle a bien tendu l'oreille et qu'elle ne nous infligera pas (trop vite) le CD de Pat' Patrouille.
          </p>
          <p>Si vous avez des soucis de lecture (ou des kilomètres à avaler en voiture); le mix est <a href="https://bit.ly/2U8cw6m" target="_blank">téléchargeable ici</a> !</p>
        </div>

      </div>
        <PlayerContextProvider>
          <PlaylistContextProvider>
            <Playlist
            jspf={jspf}
            />
          </PlaylistContextProvider>
        </PlayerContextProvider>
      <div id="footer">
        <img src={process.env.PUBLIC_URL + '/footer.jpg'}/>
      </div>
      </>
    }
    </>
  );
};

export default PlaylistPage;

    import React,{useState} from "react";
    import { Progress } from 'semantic-ui-react'

    export const ProgressClickable = (props) => {

      const [mousePercent,setmousePercent] = useState(0);

      const updateMousePercent = (e) => {
        var rect = e.target.getBoundingClientRect();
        var width = rect.right - rect.left; //width of component
        var mouseX = e.clientX - rect.left; //pixel position of mouse within component
        var percent = mouseX / width * 100;
        setmousePercent(percent);
      }

      const handleOnMouseUp = (e) => {
        if (typeof props.onMouseUp === 'function') {
          props.onMouseUp(e,mousePercent);
        }
      }

      const cssStyle = {
        pointerEvents: props.disabled ? 'none' : 'auto',
        cursor: 'pointer'
      }

      //to fix find a cleaner way to extend Progress ?
      //We should not have define every possible attribute here.
      return (
        <>
          <Progress
          percent={props.percent}
          size={props.size}
          onMouseMove={updateMousePercent}
          onMouseUp={handleOnMouseUp}
          disabled={props.disabled}
          style={cssStyle}
          className={props.className}
          />
        </>
      );
    }

import React from "react";
import AppContent from "./AppContent";

const App = (props) => {

  return (
    <AppContent/>
  );
}

export default App;

export const API_KEY = 'oXpdKXhcPDCkE0gm0tVB';
export const API_URL = 'https://wpsstmapi.herokuapp.com';//'http://localhost:3000';//
export const DEFAULT_PLAYLIST_LINK = 'radio/325';

export const DEBUG = (process.env.NODE_ENV !== 'production');

export const LASTFM_API = '0a2ed71323f72fa4339dbc4e1229815f';
export const SPOTIFY_API = 'e17c77860d404a96822f803a54666195';

export const BACKEND_URL = 'https://www.spiff-radio.org';//'http://spiff.local';
export const BACKEND_AUTH_TOKEN_URL = BACKEND_URL + '/wp-json/jwt-auth/v1/token';
export const BACKEND_API_URL = BACKEND_URL + '/wp-json/soundsystem';

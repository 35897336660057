import {filterPlayableUrls,filterNotPlayableUrls} from "./../utils/Utils";


export default class jspfTrack {

  constructor(jspf){

    this.location=     [];
    this.identifier=   [];
    this.title=        null;
    this.creator=      null;
    this.annotation=   null;
    this.info=         null;
    this.image=        null;
    this.album=        null;
    this.trackNum=     null;
    this.duration=     null;
    this.link=         [];
    this.meta=         [];
    this.extension=    [];

    if (jspf){
      for (const [key, value] of Object.entries(jspf)) {
        if ( !this.hasOwnProperty(key) ) continue;
        this[key] = value;
      }
    }

    //sort location (playable) VS links (not playable)
    const urls = this.location.concat(this.link);
    this.location = filterPlayableUrls(urls);
    this.link = filterNotPlayableUrls(urls);
  }
}

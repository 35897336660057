import React, { useContext } from "react";
import { Track } from "./Track";
import { Message } from 'semantic-ui-react';
import "./Tracklist.scss";

import { PlayerContext } from "../context/player-context";
import { PlaylistContext } from "../context/playlist-context";


export const Tracklist = (props) => {

  const [playerState, playerDispatch] = useContext(PlayerContext);
  const [playlistState, playlistDispatch] = useContext(PlaylistContext);

  const totalTrack = playlistState.track.length;

  return (
    <div className="tracklist">
      <meta itemProp="numTracks" content={totalTrack} />
      {
        //TOUFIX TOUCHECK should be in radio
        playlistState._apiError &&
        <Message negative>
          <Message.Header>{playlistState._apiError}</Message.Header>
        </Message>
      }
      {
        playlistState.track.length ?

          <div className="tracks">
            <ul>
            {

              playlistState.track.map((jspf,key) => {

                var isActive =      (playerState.activePair[0] === jspf.trackNum);
                var isCurrent =     (playerState.currentPair[0] === jspf.trackNum);
                var status =        isActive ? playerState.media_status : '';
                var progress =      isActive ? playerState.media_progress : 0;

                return (
                  <li
                  key={key}
                  >
                    <Track
                    jspf={jspf}
                    trackNum={key+1}
                    active={isActive}
                    current={isCurrent}
                    status={status}
                    progress={progress}
                    />
                  </li>
                )
              })
            }
            </ul>
          </div>
        :
          <Message>
            <Message.Header>No tracks found.</Message.Header>
          </Message>
      }

    </div>
  );

};

import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Icon } from 'semantic-ui-react'
import "./PlayButton.scss";
import classNames from "classnames";

export const PlayButton = (props) => {
  const showCircles = (props.circles !== undefined) ? props.circle : true;

  const getIcon = () => {
    if ((props.status === 'loading')){
      return (<Icon name='ellipsis horizontal'/>)
    }else if (props.error){
      return (<Icon name='exclamation triangle'/>)
    }else{
      if ((props.status === 'playing')){
        return (<Icon name='pause' className="clickable"/>)
      }else{
        return (<Icon name='play' className="clickable"/>)
      }
    }
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    if (typeof props.onClick === 'function') {
      props.onClick(e);
    }
  }

  const progressPc = props.progress ? props.progress * 100 : 0;

  return (
    <div
      className={classNames({
        'play-button':  true,
        'freeze':       (props.disabled)
      })}
      style={{width: props.size,height: props.size}}
      onClick={handleOnClick}
      >
      <div className="play-button-icons">
      {
        getIcon()
      }
      </div>
      {
        showCircles &&
        <div className="play-button-circles">
        {/*BG*/}
        <CircularProgress
          variant="determinate"
          className="progress-bg"
          size={props.size}
          value={100}
        />
        {/*OL*/}
        {
          (props.status === 'loading') ?
            <CircularProgress
            variant="indeterminate"
            className="progress-loading"
            size={props.size}
            />
          :
            <CircularProgress
            className="progress-pc"
            variant="determinate"
            size={props.size}
            value={progressPc}
            />
        }
        </div>
      }
    </div>
  );
}

// https://blog.liplex.de/axios-interceptor-to-refresh-jwt-token-after-expiration/
//https://gist.github.com/paulsturgess/ebfae1d1ac1779f18487d3dee80d1258

 import axios from "axios";
 import {API_URL} from "./Constants";
 import { ApiTokenStorage } from "./ApiTokenStorage";

 class Api {
   constructor() {
     //Set Axios client with defaults
     let client = axios.create({
       baseURL: API_URL,
       headers: {
         'Content-Type':     'application/json',
         'X-Requested-With': 'XMLHttpRequest',
       }
     });

     //request interceptors
     client.interceptors.request.use( this.preRequestToken );
     //response interceptors
     client.interceptors.response.use(this.onAuthSuccess, this.onAuthError);
     client.interceptors.response.use(this.onSuccess, this.onError);

     this.client = client;
   }

   //if token exists, add it to the request
   preRequestToken(config){
     if ( ApiTokenStorage.hasToken() ){
       config.headers.Authorization = 'Bearer ' + ApiTokenStorage.getToken()
     }
     return config;
   }

   //auth went fine. Do nothing.
   onAuthSuccess(response) {
     return response;
   }

   //auth went wrong. Maybe we can try to get a new token ?
   onAuthError(error) {
     //abord interceptor as the error is not a
     //401 (authentification error)
     //498 (token expired)
     if ( ![401,498].includes(error.response.status) ) {
       return new Promise((resolve, reject) => {
         reject(error);
       });
     }

     //clear token in localstorage
     ApiTokenStorage.clear();

     //get new token (and store it) and try request again
     return ApiTokenStorage.getNewToken()
       .then((token) => {

         const config = error.config;
         config.headers['Authorization'] = `Bearer ${token}`;

         return new Promise((resolve, reject) => {
           axios.request(config).then(response => {
             resolve(response);
           }).catch((error) => {
             reject(error);
           })
         });

       })
       .catch((error) => {
         return Promise.reject(error);
       });
   }

   onSuccess(response) {
     return response;
   }
   onError(error) {
     console.error('Request Failed:', error.config);

     let error_message = 'Unknown API error';

     if (error.response) {
       // Request was made but server responded with something
       // other than 2xx
       console.error('Status:',  error.response.status);
       console.error('Data:',    error.response.data);
       console.error('Headers:', error.response.headers);

       if (error.response.data.error){
         error_message = error.response.data.error;
       }

     } else {
       // Something else happened while setting up the request
       // triggered the error
       if (error.message){

         error_message = error.message;
       }

     }

     return Promise.reject('API error: ' + error_message);


   }
   request(config){
     return this.client(config);
   }
}
export default new Api();

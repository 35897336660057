import jspfTrack from  "./jspfTrack";

export default class jspfPlaylist {
  constructor(jspf){

    var playlist = this;

    this.title=        null;
    this.creator=      null;
    this.annotation=   null;
    this.info=         null;
    this.location=     null;
    this.identifier=   null;
    this.image=        null;
    this.date=         null;
    this.license=      null;
    this.attribution=  [];
    this.link=         [];
    this.meta=         [];
    this.extension=    [];
    this.track=        [];

    if (jspf){
      for (const [key, value] of Object.entries(jspf)) {
        if ( !this.hasOwnProperty(key) ) continue;
        this[key] = value;
      }
    }

    //build tracks
    this.track.forEach(function(jspf, index) {
      jspf.trackNum = index + 1;
      jspf = new jspfTrack(jspf);
      playlist.track[index] = jspf;
    });

  }

}

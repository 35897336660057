import React,{useContext} from "react";
import classNames from "classnames";
import { Icon,Button } from 'semantic-ui-react'
import { ProgressClickable } from "./ProgressClickable";
import {DEBUG} from "./../Constants";
import { PlayerContext } from "../context/player-context";
import { PlaylistContext } from "../context/playlist-context";


export const PlayerControls = (props) => {

  const [playerState, playerDispatch] = useContext(PlayerContext);
  const [playlistState, playlistDispatch] = useContext(PlaylistContext);


  const canPlayTrack = playlistState.track.length;
  const canPauseTrack = (canPlayTrack && playerState.playing);

  const onScrobblerToggle = (e) => {
    e.preventDefault();

    playerDispatch({
      type: "TOGGLE_SCROBBLER"
    });
  }

  const handleTogglePlayingBt = (e) => {
    playerDispatch({
      type: "TOGGLE_PLAYING"
    });
  }

  const handleSeekTo = (e,percent) => {
    if (typeof props.onSeekTo === 'function') {
      props.onSeekTo(percent/100);
    }
  }

  const handleWidgetBt = (e) => {
    e.preventDefault();
    playerDispatch({
      type: "TOGGLE_WIDGET"
    });
  }

  const handleLoopingBt = (e) => {
    e.preventDefault();
    playerDispatch({
      type: "TOGGLE_LOOPING"
    });
  }

  const handleMuteBt = (e) => {
    e.preventDefault();
    handleMute();
  }


  const handleMute = (bool) => {
    playerDispatch({
      type: "TOGGLE_MUTED",
      payload:bool
    });
  }

  const handleVolumeSet = (e,percent) => {
    e.preventDefault();
    playerDispatch({
      type: "SET_VOLUME",
      payload:percent/100
    });

    //toggle mute
    let muted = (percent === 0);
    handleMute(muted);
  }

  return (
    <div className="player-controls">
      <ProgressClickable
      percent={playerState.media_progress * 100}
      size="tiny"
      onMouseUp={handleSeekTo}
      disabled={!playerState.currentPair.length}
      className='player-control-seek'
      />
      <div className="player-controls-buttons">
        {playerState.playing &&
          <Button icon circular onClick={handleTogglePlayingBt} disabled={!canPauseTrack}>
            <Icon name='pause' />
          </Button>
        }
        {!playerState.playing &&
          <Button icon circular onClick={handleTogglePlayingBt} disabled={!canPlayTrack}>
            <Icon name='play' />
          </Button>
        }

        <Button icon circular onClick={props.onPrevious} disabled={!props.hasPrevious}>
          <Icon name='backward' />
        </Button>

        <Button icon circular onClick={props.onNext} disabled={!props.hasNext}>
          <Icon name='forward' />
        </Button>

        <div
        className={classNames({
          'player-control-volume': true,
          'muted': playerState.muted
        })}
        >
          <div>
            {playerState.muted &&
              <Button icon circular onClick={handleMuteBt}>
                <Icon name='volume off' />
              </Button>
            }
            {!playerState.muted &&
              <Button icon circular onClick={handleMuteBt}>
                <Icon name='volume up' />
              </Button>
            }
          </div>
          <ProgressClickable
          percent={playerState.volume * 100}
          onMouseUp={handleVolumeSet}
          />
        </div>
        {playerState.widget &&
          <Button icon circular onClick={handleWidgetBt}>
            <Icon name='compress' />
          </Button>
        }
        {!playerState.widget &&
          <Button icon circular onClick={handleWidgetBt}>
            <Icon name='expand' />
          </Button>
        }
        <Button icon circular
        onClick={handleLoopingBt}
        className={classNames({
          'player-control-looping': true,
          'active': playerState.looping
        })}
        >
          <Icon name='redo' />
        </Button>
      </div>
    </div>
  )
}

export class LastLocationPath {
  static LOCAL_LAST_PATH = 'last_history_path';

  static get(): void{
    const pathname = localStorage.getItem(this.LOCAL_LAST_PATH);
    if (!pathname) return;
    console.log("Get last location path",pathname);
    return pathname;
  }

  static save(location): void{
    if (!location) return;
    const pathname = location.pathname;
    console.log("Save last location path:",pathname);
    localStorage.setItem(this.LOCAL_LAST_PATH,pathname);
  }

}
